import React, { useEffect } from "react";
import "./contactUs.scss";
import Navbar from "../../components/navbar/Navbar";
import HeadImage from "../../assets/imgs/headcontact.png";
import Sms from "../../assets/imgs/sms.png";
import PhoneCell from "../../assets/imgs/call-calling.png";
import Facebook from "../../assets/imgs/facebook.png";
import Instagram from "../../assets/imgs/instagram.png";
import AOS from "aos";
import "aos/dist/aos.css";
const ContactUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className='contactUs'>
      <Navbar value={true} />
      <div className='contactUs-container container'>
        <div data-aos='fade-right' className='left'>
          <div className='heading'>
            <img src={HeadImage} alt='' />
            <h3>Technical support team</h3>
          </div>
          <div className='contact'>
            <a href="mailto:stepout@gmail.com">

              <div className='item'>
                <img src={Sms} alt="" className="icon" />
                <div className="itemTexts">
                  <h4>Email Address</h4>
                  <p>stepout@gmail.com</p>
                </div>
              </div>
            </a>

            <a href="tel:+97338226888">
              <div className="item">
                <img src={PhoneCell} alt="" className="icon" />
                <div className="itemTexts">
                  <h4>Phone Number</h4>

                  <p>+97338226888</p>
                </div>

              </div>
            </a>

          </div>
          <div className='links'>
            <h4>Follow Us</h4>
            <div className='iconsLinks'>
              <a href='https://www.instagram.com/stepout609'>
                {" "}
                <img src={Instagram} alt='' className='icons' />

              </a>
              <a href='https://www.facebook.com/profile.php?id=61555523706930'>
                <img src={Facebook} alt='' className='icons' />

              </a>
            </div>
          </div>
        </div>
        <div data-aos='fade-left' className='right'>
          <form action=''>
            <div className='input'>
              <label htmlFor='name'>Name</label>
              <input id='name' placeholder='Enter your name' type='text' />
            </div>
            <div className='input'>
              <label htmlFor='email'>Email</label>
              <input placeholder='Enter your email' id='email' type='text' />
            </div>
            <div className='input'>
              <label htmlFor='phonenumber'>Phone Number</label>
              <input
                placeholder='Enter your phone number'
                id='phonenumber'
                type='text'
              />
            </div>
            <div className='input'>
              <label htmlFor='title'>Title</label>
              <input placeholder='Enter your title' id='title' type='text' />
            </div>
            <div className='input'>
              <label htmlFor='message'>Message</label>
              <textarea
                placeholder='Enter your Message'
                id='message'
                cols='30'
                rows='10'
              ></textarea>
            </div>
            <button type='submit'>Send Email</button>
          </form>
        </div>
      </div>
    </section >
  );
};

export default ContactUs;
